<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="$t('Office:')"
        prop="office"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <el-select
          v-model="form.office"
          placeholder="Select Office"
          :disabled="data"
          class="w-100"
          @change="getUsers"
        >
          <el-option
            v-for="item in offices"
            :key="item.id"
            :label="item.office_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('Client Name:')" prop="clientName">
        <el-input type="text" v-model="form.clientName"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('State:')"
        prop="state"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <el-select
          v-model="form.state"
          placeholder="Select State"
          class="w-100"
          @change="getForms"
        >
          <el-option
            v-for="item in states"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="'Default Form'" prop="form">
        <v-select
          class="style-chooser"
          placeholder="Select a form"
          :options="forms"
          label="name"
          :reduce="(form) => form.id"
          v-model="form.form"
        ></v-select>
      </el-form-item>

      <el-form-item :label="$t('Bank:')" prop="bank">
        <el-select v-model="form.bank" placeholder="Select bank" class="w-100">
          <el-option
            v-for="item in banks"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('Bank Account:')" prop="account">
        <el-input type="text" v-model="form.account"></el-input>
      </el-form-item>

      <el-form-item :label="$t('Start Date')" prop="startdate">
        <el-date-picker
          type="date"
          v-model="form.startdate"
          value-format="yyyy-MM-dd"
          format="MM/dd/yyyy"
          placeholder="Pick a day"
          class="w-100"
        ></el-date-picker>
      </el-form-item>

      <el-form-item :label="$t('Assign to')" prop="assignto">
        <el-select
          v-model="form.assignto"
          placeholder="Select User"
          class="w-100"
        >
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-button type="primary" @click="submitForm('form')"
        >Save Register</el-button
      >
    </el-form>
  </div>
</template>

<script>
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import clientSaleTax from "@/services/api/clientSaleTax";
import user from "@/services/api/user";
import form from "@/services/api/form";
import bank from "@/services/api/bank";
import stateSalesTax from "@/services/api/stateSalesTax";

export default {
  props: ["data"],
  data() {
    return {
      offices: [],
      users: [],
      forms: [],
      banks: [],
      states: [],
      form: {
        id: null,
        office: null,
        clientName: null,
        state: null,
        form: null,
        bank: null,
        account: null,
        startdate: null,
        assignto: null,
        active: 0,
      },
    };
  },
  mounted() {
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee":
        this.discriminator = "office";
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
    }
    form.get().then((response) => {
      this.forms = response;
    });
    bank.get().then((response) => {
      this.banks = response;
    });
    stateSalesTax.get().then((response) => {
      this.states = response;
    });
  },
  methods: {
    getUsers() {
      user.getUsersByOffice(this.form.office).then((response) => {
        this.users = response;
      });
    },
    getForms() {
      form.getByState(this.form.state).then((response) => {
        this.forms = response;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          clientSaleTax
            .create(this.form)
            .then((response) => {
              this.$emit("process", { data: response });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: 45px;
}
.style-chooser .vs__dropdown-menu {
  max-height: 150px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
