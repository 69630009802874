import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/forms');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getByState(id) {
        try {
            const response = await axios.get(`/forms/${id}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/form', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/form/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async delete(data) {
        try {
            const response = await axios.put(`/form/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
