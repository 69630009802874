import axios from 'axios'

export default {
    async getById(data) {
        try {
            const response = await axios.get(`/user/${data}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getOffice() {
        try {
            const response = await axios.get('/user/office');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async getUsersByOffice(id) {
        try {
            const response = await axios.get('/user/office/'+id);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/user', {
                name: data.name,
                phone: data.phone,
                email: data.email,
                status: data.status,
                discriminator: data.discriminator
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/user/${data.id}`, {
                name: data.name,
                phone: data.phone,
                email: data.email,
                old_email: data.old_email,
                status: data.status
            });
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async changePassword(data) {
        try {
            const response = await axios.put(`/changePassword`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
