import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/clientstax');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/clienttax', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/clienttax/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async delete(data) {
        try {
            const response = await axios.put(`/clienttax/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async reviewAndGenerate() {
        try {
            const response = await axios.post(`/clientstax/review/generate`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
}
